import React, { useState, useEffect, useRef } from "react"
import Seo from "../../components/Seo"
import Layout from "../../components/Layout"
import FormInput from "../../components/FormInput"
import Breadcrumbs from "../../components/Breadcrumbs"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import Sidebar from "../../components/CompanyProfileSidebar"
import { MultiSelect } from "react-multi-select-component"
import MemberJobPostings from "../../components/MemberJobPostings"
import { Notification } from "../../components/ToastNotification"
import { getAll as getAllPostings, create as createJobPosting } from "../../dataAccess/wordPress/jobPostings"
import Modal from "../../components/Modal"
import ModalModules, { purchaseAdditional as purchaseAdditionalForm } from "../../components/ModalModules"
import { additionaJobPostingSku } from "../../stripe"
import { v4 as uuid } from "uuid"

const maxPostsByMembership = {
  Entry: 0,
  "Community (Entry)": 0,
  Base: 2,
  "Bronze (Base)": 2,
  Enhanced: 7,
  Premium: 12,
}

const JobPostings = ({ location, buyButton }) => {
  const [data, setMemberData] = useState()
  const [selectCat, setCatSelected] = useState([])
  const [selectStac, setStacSelected] = useState([])
  const [selectPostType, setPostTypeSelected] = useState([])
  const [allPostings, setAllPostings] = useState([])
  const purchaseAdditionalRef = useRef()
  const memberPostings = allPostings.filter((p) => p.title === data?.id)
  const [getAddtionalPurchase, setAddtionalPurchase] = useState(Number)
  const [postingJob, setPostingJob] = useState(false)

  const membershipLevel = data?.membership.name
  const maxPostsAllowed = maxPostsByMembership[membershipLevel ?? "Entry"]
  const postCount = allPostings.map((p) => p.title).reduce((total, id) => (id === data?.id ? total + 1 : total), 0)
  const additionalPurchases = () => {
    if (isNaN(parseInt(data?.additionalpostpurchases))) {
      return 0
    }
    window.MemberStack.reload()
    return parseInt(data?.additionalpostpurchases)
  }
  const postingDisallowed = postCount >= maxPostsAllowed + additionalPurchases()
  const boughtPost = postCount >= maxPostsAllowed

  useEffect(() => {
    if (window.MemberStack.onReady) {
      window.MemberStack.onReady
        .then(function (member) {
          setMemberData(member)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }, [])

  useEffect(() => {
    async function setPosts() {
      setAllPostings(await getAllPostings())
    }
    const jobPostings = setPosts()
  }, [])

  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: "Job Postings",
  })

  if (postingDisallowed) {
    buyButton = (
      <a href="" className="button button--primary" onClick={(e) => purchaseAdditional(e)}>
        Buy a Job Post
      </a>
    )
  } else {
    buyButton = (
      <button type="submit" className="button button--primary" disabled={postingJob}>
        {!postingJob ? "Submit Job Post" : "Submitting..."}
      </button>
    )
  }

  const updateJobPost = async (e) => {
    e.preventDefault()
    setPostingJob(true)
    if (postingDisallowed) {
      //return purchaseAdditional()
      return Notification("Sorry! You've reached your job post limit. You will be redirected to purchse more Job Post.", "fas fa-times-circle", "error")
    }

    const formData = new FormData(e.target)
    const obj = {}

    for (const entry of formData.entries()) {
      obj[entry[0]] = entry[1]
    }

    if (boughtPost) {
      const now = new Date()
      const startDate = new Date(now.toUTCString())
      const endDate = new Date(new Date(new Date().setDate(now.getDate() + 30)).toUTCString())
      await createJobPosting(data.id, obj.job_title, obj.job_description, obj.url, selectStac, selectCat, obj.featured, obj.type_of_position, data.industry, data["company-name"], data["company-url"], data.city, data.state, startDate, endDate)
    } else {
      await createJobPosting(data.id, obj.job_title, obj.job_description, obj.url, selectStac, selectCat, obj.featured, obj.type_of_position, data.industry, data["company-name"], data["company-url"], data.city, data.state)
    }

    Notification("success! job posting created", "fas fa-check-circle", "success")
    setAllPostings(await getAllPostings())
    setPostingJob(false)
  }

  const purchaseAdditional = () => {
    purchaseAdditionalRef.current.openModal()
  }

  const onAdditionalPurchased = async (additionalCount) => {
    if (!data) {
      Notification("We were unable to process your purchase. Please contact customer support.", "fas fa-times-circle", "error")
      return
    }

    const now = new Date()
    const utcNow = new Date(now.toUTCString())
    const utcNowPlusThirty = new Date(new Date(new Date().setDate(now.getDate() + 30)).toUTCString())
    const additionalPurchaseFactory = () => ({
      token: uuid(),
      expirationDate: utcNowPlusThirty.toUTCString(),
    })

    const newAdditionalPurchases = Array(additionalCount)
      .fill(additionalPurchaseFactory)
      .map((f) => f())
    const additionalPurchases = (data.additionalPostPurchases?.filter((p) => new Date(p.expirationDate) > utcNow) ?? []).concat(newAdditionalPurchases)

    await data.updateProfile({ additionalpostpurchases: additionalPurchases }, true)

    //todo for expired posts give a visual indicator and a link to reactive it
    window.MemberStack.reload()
    setMaxPostsAllowed(maxPostsAllowed + additionalCount)
    purchaseAdditionalRef.current.closeModal()
  }
  const category = [
    { label: "Software Developer", value: "Software Developer" },
    { label: "Data + Analytics", value: "Data + Analytics" },
    { label: "Sales", value: "Sales" },
    { label: "Content", value: "Content" },
    { label: "Marketing", value: "Marketing" },
    { label: "UX + Design", value: "UX + Design" },
    { label: "HR/Admin", value: "HR/Admin" },
    { label: "IT", value: "IT" },
    { label: "Product", value: "Product" },
    { label: "Engineer", value: "Engineer" },
  ]

  const techStack = [
    { label: "Java", value: "Java" },
    { label: "Linux", value: "Linux" },
    { label: "Python", value: "Python" },
    { label: "C++", value: "C++" },
    { label: ".Net", value: ".Net" },
    { label: "JavaScript", value: "JavaScript" },
    { label: "Front End", value: "Front End" },
    { label: "DevOps", value: "DevOps" },
    { label: "Other", value: "Other" },
  ]

  const positions = [
    { label: "Full Time", value: "Full Time" },
    { label: "Part Time", value: "Part Time" },
    { label: "Remote", value: "Remote" },
    { label: "Freelance", value: "Freelance" },
  ]

  let today = new Date()
  for (let item of allPostings) {
    if (item.jobPostings.startDate != "Invalid Date" && item.jobPostings.endDate != "Invalid Date") {
      if (today.getDate() >= item.jobPostings.endDate.getDate()) {
        deleteJobPosting(item.databaseId)
        const additionalPurchases = data.additionalpostpurchases - 1
        data.updateProfile({ additionalpostpurchases: additionalPurchases }, true)
      } else {
        //console.log("Not Expired!")
      }
    }
  }

  return (
    <Layout>
      <form id="jobPost" onSubmit={updateJobPost}>
        <Seo title="Job Postings" />
        <Breadcrumbs crumbs={crumbs} />
        <div className="profiles profiles--edit container">
          <div className="row profile__layout">
            <Sidebar sidebar={data} />
            <div className="content">
              <div className="content__header">
                <h1>Job Postings</h1>
              </div>
              <div className="profiles__forms">
                <div className="profiles__forms--cols">
                  <FormInput name="job_title" type="text" required label="Job Title" />
                  <FormInput name="url" type="text" required label="Posting URL" placeholder="ex. https://www.example.com/ " />

                  <div className="form-field dropdown ">
                    <label htmlFor="state">Type of Position</label>
                    <div className="select-container">
                      <div className="select-container__select">
                        <select name="type_of_position" id="type_of_position">
                          <option value="">- Select -</option>
                          {positions.map((e, i) => {
                            return (
                              <option defaultValue={e.value} key={i}>
                                {e.label}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="profiles__forms--cols">
                  <div className="form-field dropdown multiselect">
                    <label htmlFor="category">Role Category</label>
                    <MultiSelect id="category" name="role_category" options={category} value={selectCat} onChange={setCatSelected} overrideStrings={{ selectSomeItems: "- Select -" }} required />
                  </div>
                  <div className="form-field dropdown multiselect">
                    <label htmlFor="techStack">Tech Stack</label>
                    <MultiSelect id="techStack" name="tech_stack" options={techStack} value={selectStac} onChange={setStacSelected} overrideStrings={{ selectSomeItems: "- Select -" }} required />
                  </div>
                </div>
              </div>
              <FormInput name="job_description" type="textarea" required label="Job Description" maxlength="700" />
              <FormInput name="featured" type="slider" label="Featured?" mscontent="not-allowed" />
              <div className="form-controls">{buyButton}</div>
              {data && <MemberJobPostings allPostings={allPostings} onMemberPostsChanged={() => getAllPostings().then((postings) => setAllPostings(postings))} data={data} />}
            </div>
          </div>
        </div>
      </form>
      <Modal ref={purchaseAdditionalRef}>
        <ModalModules ref={purchaseAdditionalRef} formDisplayed={purchaseAdditionalForm} title="Purchase Additional Job Postings" stripeSku={additionaJobPostingSku} onAdditionalPurchased={onAdditionalPurchased}>
          Additional Job Postings cost $100 each per month.
        </ModalModules>
      </Modal>
    </Layout>
  )
}

export default JobPostings
