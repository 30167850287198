import { getTokenAnon as getAToken } from "../../dataAccess/token/getToken"

//why is this undefined? because it isn't prefixed with the magic GATSBY_ to expose to UI
//const url = process.env.SITE_URL;
const url = "https://edit.choosemketech.org"

const mapWordPressToObject = (post) => ({
  id: post.id,
  databaseId: post.id,
  title: post.title.rendered,
  date: post.date,
  modified: post.modified,
  jobPostings: {
    featured: post.acf.featured ?? false,
    jobDescription: post.acf.job_description,
    jobTitle: post.acf.job_title,
    roleCategory: post.acf.role_category,
    techStack: post.acf.tech_stack,
    typeOfPosition: post.acf.type_of_position,
    url: post.acf.url,
    companyIndustry: post.acf.industry,
    companyName: post.acf.company_name,
    companyUrl: post.acf.company_url,
    companyCity: post.acf.company_city,
    companyState: post.acf.company_state,
    startDate: new Date(post.acf.starttime),
    endDate: new Date(post.acf.endtime),
  },
})

export const create = async (id, jobTitle, jobDescription, jobUrl, techStack, roleCategory, featured, typeOfPosition, companyIndustry, companyName, companyUrl, companyCity, companyState, startDate, endDate) => {
  return fetch(url + "/wp-json/wp/v2/job_postings/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `Bearer ${await getAToken()}`,
    },
    body: JSON.stringify({
      title: id,
      fields: {
        memberstack_id: id,
        job_title: jobTitle,
        job_description: jobDescription,
        url: jobUrl,
        tech_stack: techStack,
        role_category: roleCategory,
        featured: featured,
        type_of_position: typeOfPosition,
        industry: companyIndustry,
        company_name: companyName,
        company_url: companyUrl,
        company_city: companyCity,
        company_state: companyState,
        starttime: startDate,
        endtime: endDate,
      },
      status: "publish",
    }),
  })
    .then(function (response) {
      return response.json()
    })
    .then(function (jobpost) {
      console.log(jobpost)
    })
}

export const get = async (id) => {
  const post = await fetch(url + `/wp-json/wp/v2/job_postings/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `Bearer ${await getAToken()}`,
    },
  })
  return mapWordPressToObject(await post.json())
}

export const getAll = async () => {
  const posts = await fetch(url + `/wp-json/wp/v2/job_postings/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `Bearer ${await getAToken()}`,
    },
  })
  const data = await posts.json()
  return Array.isArray(data) ? data.map(mapWordPressToObject) : []
}

export const deleteJobPosting = async (id) =>
  fetch(url + `/wp-json/wp/v2/job_postings/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `Bearer ${await getAToken()}`,
    },
  })

export const update = async (id, jobPosting) =>
  fetch(url + `/wp-json/wp/v2/job_postings/${id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `Bearer ${await getAToken()}`,
    },
    body: JSON.stringify(jobPosting),
  })
