import React from "react"
import {INDUSTRIES} from "../constants/industries";

//todo consolidate into a single map with the job-postings page

const positionTypes = INDUSTRIES;

const industryBadgeFactory = (categories) => badgeFactory(categories, positionTypes)

const badgeFactory = (categories, categoryMap) => {
  if(typeof categories === "string"){
    let cat = JSON.parse(categories);
    categories = cat;
  }
  return categories?.map((c) => {
    const { categoryClass, displayName } = categoryMap[c] || {}

    return (
      <span key={displayName} className={categoryClass}>
        {displayName}
      </span>
    )
  })
}

const JobPostingCard = ({ posting, company, onEdit, onDelete, companyInfo, jobTypeInfo, data, posted, getIndustry }) => {
  const { jobPostings: job } = posting

  // This shows expiration of bought posts in days
  if (!isNaN(new Date(posting.jobPostings.endDate)) && data.id === posting.title) {
    const day = 24 * 60 * 60 * 1000
    const startDate = new Date(posting.jobPostings.startDate)
    const endDate = new Date(posting.jobPostings.endDate)
    const result = Math.round((endDate - startDate) / day)
    posted = (
      <span>
        Posted {new Date(posting.date).toDateString()} - Expires in {result} days
      </span>
    )
  } else {
    posted = <span>Posted {new Date(posting.date).toDateString()}</span>
  }

  if (company.city && company.state) {
    companyInfo = (
      <h5>
        <i className="fas fa-map-marker-alt" /> {company.city}, {company.state}
      </h5>
    )
  }
  if (job.typeOfPosition) {
    jobTypeInfo = (
      <h5>
        <i className="fas fa-calendar-alt" /> {job.typeOfPosition}
      </h5>
    )
  }

  getIndustry = []

  if (data?.industry) {
    for (let item in positionTypes) {
      if (data.industry === positionTypes[item].displayName) {
        getIndustry.push(data.industry)
      }
    }
  }

  return (
    <div className="card job-card job-card--featured">
      <div className="job-card__left">
        <div className="job-card__left__heading">
          <h3>{job.jobTitle}</h3>
        </div>
        <p>{job.jobDescription}</p>
        <div className="job-card__right__controls">
          <span className="control" onClick={onEdit}>
            edit
          </span>{" "}
          |{" "}
          <span className="control" onClick={onDelete}>
            delete
          </span>
        </div>
        <div className="job-card__left__tags">
          {job.featured && <span className="tag tag--gold tag--gold--featured">Featured</span>}
          {job.companyIndustry && industryBadgeFactory(getIndustry.map((ind) => ind))}
          {/* Hidden until time for fix can be found */}
          {/* {job.roleCategory && categoryBadgeFactory(getRoles?.map((rc) => rc.label))} */}
        </div>
        <div className="job-card__left__date">{posted}</div>
      </div>
      <div className="job-card__right">
        <div className="job-card__right__details">
          <h5>
            <i className="fas fa-building" /> {company.name}
          </h5>
          {companyInfo}
          {jobTypeInfo}
        </div>
        <div className="job-card__right__cta">
          <a className="button button--external button--primary" href={job.url} target="_blank">
            Apply For Jobs
          </a>
          {/* <a className="link" href={`//${company.url}`}>
            View Company Profile
          </a> */}
        </div>
      </div>
    </div>
  )
}

export default JobPostingCard
