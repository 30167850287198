import React, { useState, useEffect, useRef } from "react"
import { getAll as getAllPostings, get as getJobPosting, update as updateJobPosting, deleteJobPosting } from "../dataAccess/wordPress/jobPostings"
import JobPostingCard from "./JobPostingCard"
import Modal from "./Modal"
import ModalModules, { editPostForm, deletePostForm } from "./ModalModules"

const MembershipJobPostings = ({ allPostings, onMemberPostsChanged, data }) => {
  const [memberPosts, setMemberPosts] = useState([])
  const [company, setCompany] = useState()
  const [editingPost, setEditingPost] = useState()
  const [deletingPost, setDeletingPost] = useState()
  const editPostingRef = useRef()
  const deletePostingRef = useRef()

  const editPostFactory = (post) => () => {
    setEditingPost(post)
    editPostingRef.current.openModal()

    return false
  }

  const onPostEdited = async (post) => {
    if (!post) return

    const job = post.jobPostings

    const fullPost = await getJobPosting(post.databaseId)
    await updateJobPosting(post.databaseId, {
      ...fullPost,
      modified: new Date(),
      fields: {
        ...fullPost.acf,
        job_title: job.jobTitle,
        job_description: job.jobDescription,
        url: job.url,
        tech_stack: job.techStack,
        role_category: job.roleCategory,
        featured: job.featured,
        type_of_position: job.typeOfPosition,
        company_name: company.name,
        company_url: company.url,
        company_city: company.city,
        company_state: company.state,
      },
    })

    const editedIndex = memberPosts.findIndex((p) => p.id === editingPost.id)
    setMemberPosts([...memberPosts.slice(0, editedIndex), post, ...memberPosts.slice(editedIndex + 1)])
    editPostingRef.current.closeModal()
  }

  const deletePostFactory = (post) => (e) => {
    e.preventDefault()
    setDeletingPost(post)
    deletePostingRef.current.openModal()

    return false
  }

  const onPostDeleted = async (post) => {
    await deleteJobPosting(post.databaseId)
    setMemberPosts(memberPosts.filter((p) => p.id !== post.id))
    deletePostingRef.current.closeModal()
    onMemberPostsChanged()
  }

  useEffect(() => {
    if (window.MemberStack.onReady) {
      window.MemberStack.onReady
        .then((member) => {
          setMemberPosts(allPostings.filter((p) => p.title === member.id))
        })
        .catch((e) => console.log(e))
    }
  }, [allPostings])

  useEffect(() => {
    if (window.MemberStack.onReady) {
      window.MemberStack.onReady
        .then((member) => {
          setCompany({
            name: member["company-name"],
            url: member["company-url"],
            city: member.city,
            state: member.state,
          })
        })
        .catch((e) => console.log(e))
    }
  }, [])

  return (
    <div>
      <Modal ref={editPostingRef}>
        <ModalModules ref={editPostingRef} formDisplayed={editPostForm} jobPosting={editingPost} onPostingEdited={onPostEdited} />
      </Modal>
      <Modal ref={deletePostingRef}>
        <ModalModules ref={deletePostingRef} formDisplayed={deletePostForm} jobPosting={deletingPost} onPostingDeleted={onPostDeleted} />
      </Modal>
      {memberPosts
        .sort((p1, p2) => p1.modified < p2.modified)
        .map((p) => (
          <JobPostingCard key={p.id} posting={p} company={company} onEdit={editPostFactory(p)} onDelete={deletePostFactory(p)} data={data} />
        ))}
    </div>
  )
}

export default MembershipJobPostings
