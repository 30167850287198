export const INDUSTRIES = {
    Education: {
        label: "Education",
        value: "Education",
        displayName: "Education",
        categoryClass: "tag tag--blue tag--blue--education"
    },
    ["Finance / Insurance"]: {
        label: "Finance / Insurance",
        value: "Finance / Insurance",
        displayName: "Finance / Insurance",
        categoryClass: "tag tag--blue tag--blue--finance"
    },
    Healthcare: {
        label: "Healthcare",
        value: "Healthcare",
        displayName: "Healthcare",
        categoryClass: "tag tag--blue tag--blue--healthcare"
    },
    Logistics: {
        label: "Logistics",
        value: "Logistics",
        displayName: "Logistics",
        categoryClass: "tag tag--blue tag--blue--logistics"
    },
    Manufacturing: {
        label: "Manufacturing",
        value: "Manufacturing",
        displayName: "Manufacturing",
        categoryClass: "tag tag--blue tag--blue--manufacturing"
    },
    Media: {
        label: "Media",
        value: "Media",
        displayName: "Media",
        categoryClass: "tag tag--blue tag--blue--news",
    },
    ["News / Entertainment"]: {
        label: "News / Entertainment",
        value: "News / Entertainment",
        displayName: "News / Entertainment",
        categoryClass: "tag tag--blue tag--blue--news"
    },
    Retail: {
        label: "Retail",
        value: "Retail",
        displayName: "Retail",
        categoryClass: "tag tag--blue tag--blue--retail"
    },
    ["Tech Services"]: {
        label: "Tech Services",
        value: "Tech Services",
        displayName: "Tech Services",
        categoryClass: "tag tag--blue tag--blue--software"
    },
    ["Travel / Hospitality"]: {
        label: "Travel / Hospitality",
        value: "Travel / Hospitality",
        displayName: "Travel / Hospitality",
        categoryClass: "tag tag--blue tag--blue--travel"
    },
    ["Utilities / Water Tech"]: {
        label: "Utilities / Water Tech",
        value: "Utilities / Water Tech",
        displayName: "Utilities / Water Tech",
        categoryClass: "tag tag--blue tag--blue--pharma"
    },
    Technology: {
        label: "Technology",
        value: "Technology",
        displayName: "Technology",
        categoryClass: "tag tag--blue tag--blue--software"
    },
    ["Non-Profit"]: {
        label: "Non-Profit",
        value: "Non-Profit",
        displayName: "Non-Profit",
        categoryClass: "tag tag--blue tag--blue--nonprofit"
    },
    Other: {
        label: "Other",
        value: "Other",
        displayName: "Other",
        categoryClass: "tag tag--blue tag--blue--other"
    },
};

export const INDUSTRIES_LIST = Object.values(INDUSTRIES);