import React from "react"
import { Link } from "gatsby"

const CompanyProfileSidebar = ({ sidebar, user, memberShipName }) => {
  if (sidebar) {
    memberShipName = sidebar.membership.name
  }

  if (memberShipName !== "Candidate") {
    user = (
      <ul>
        <li>
          <h4>Company</h4>
        </li>
        <li>
          <Link to="/member/company-profile" className="link">
            Company Profile
          </Link>
        </li>
        <li>
          <Link to="/member/job-postings" className="link">
            Job Postings
          </Link>
        </li>

        <li>
          <Link to="/recruit-with-us" className="link">
            Tiers+ Billing
          </Link>
        </li>
        <li>
          <a href="/#/ms/profile" className="link">
            Payment Info
          </a>
        </li>
        <li>
          <h4>Your Account</h4>
        </li>
        <li>
          <Link to="/member/account-settings" className="link">
            Account Settings
          </Link>
        </li>
      </ul>
    )
  }
  if (memberShipName === "Candidate") {
    user = (
      <ul>
        <li>
          <h4>Candidate</h4>
        </li>
        <li>
          <Link to="/candidate/member-profile" className="link">
            Your Profile
          </Link>
        </li>
      </ul>
    )
  }

  return (
    <>
      <div className="sidebar left">
        {user}
        <ul>
          <li>
            <Link to="/help" className="link">
              Help
            </Link>
          </li>
          <li>
            <Link to="/help" className="link">
              FAQ
            </Link>
          </li>
          <li>
            <a href="/#/ms/logout" className="link">
              Log Out
            </a>
          </li>
        </ul>
      </div>
    </>
  )
}

export default CompanyProfileSidebar
